import React from "react";
import { Container } from "react-bootstrap";
import { renderDynamicComponents } from "../components";
import Breadcrumb from "../modules/breadcrumb";
import Layout from "../modules/layout";
import News from "../modules/news";
import SEO from "../modules/seo";

const ContentPage = ({ pageContext }) => {
  const {
    name,
    contentComponents,
    node_locale,
    settings,
    breadcrumbs,
    showBreadcrumbNavigation,
    showNews,
  } = pageContext;
  const siteSettings = settings;
  return (
    <Layout
      siteSettings={siteSettings}
      showBreadcrumbNavigation={showBreadcrumbNavigation}
      showNews={showNews}
    >
      <SEO
        title={`${siteSettings.siteName} - ${name}`}
        description={siteSettings.siteDescription.siteDescription}
        author={siteSettings.siteAuthor}
      />
      <Breadcrumb
        visible={showBreadcrumbNavigation}
        breadcrumbs={breadcrumbs}
      ></Breadcrumb>
      <Container fluid>
        {contentComponents &&
          renderDynamicComponents(contentComponents, node_locale)}
      </Container>
      <News node_locale={node_locale} settings={settings} visible={showNews} />
    </Layout>
  );
};

export default ContentPage;
