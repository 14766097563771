import PropTypes from "prop-types";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const Breadcrumb = ({visible, breadcrumbs }) => {
    return visible ? (
        <Container fluid className={"py-4"}>
            <Row>
                <Col xs={12}>
                    <ul className={"breadcrumb-navigation"}>
                        {breadcrumbs.map(breadcrumb => (
                            <li className={"breadcrumb-navigation-item"}>
                                <a href={"/" + breadcrumb.slug}>{breadcrumb.name}</a>
                            </li>
                        ))}
                    </ul>

                </Col>
            </Row>
        </Container>
    ) : ""
};

Breadcrumb.propTypes = {
    visible: PropTypes.bool,
    breadcrumbs: PropTypes.array
};
Breadcrumb.defaultProps = {
    visible: false,
    breadcrumbs: []
};

export default Breadcrumb;
